import React, { createContext, useState, useEffect } from "react";
import { HASURA_ENDPOINT_DEV } from "../config";
import {
  createClient,
  Provider,
  defaultExchanges,
  subscriptionExchange,
} from "urql";
import "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import firebase from "firebase/compat/app";
import { SubscriptionClient } from "subscriptions-transport-ws";
// import "firebaseui/dist/firebaseui.css";
// import { firebaseConfig } from "../config";

export const AuthContext = createContext({});

// const firebaseui = require("firebaseui");

const AuthProvider = ({ children }) => {
  const authc = getAuth();
  const [auth, setAuth] = useState({ loading: true, data: null });
  const [profile, setProfile] = useState({ pLoading: true, data: null });

  // firebase.initializeApp(firebaseConfig);
  const subscriptionClient = new SubscriptionClient(
    `wss://${HASURA_ENDPOINT_DEV}`,
    {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${auth?.data?.jwt}`,
        },
      },
    }
  );

  let headers = {};

  if (auth?.data?.jwt) {
    headers = {
      Authorization: `Bearer ${auth?.data?.jwt}`,
    };
  }

  const client = createClient({
    url: `https://${HASURA_ENDPOINT_DEV}`,
    exchanges: [
      ...defaultExchanges,
      subscriptionExchange({
        forwardSubscription(operation) {
          return subscriptionClient.request(operation);
        },
      }),
    ],
    requestPolicy: "network-only",
    fetch: fetch,
    fetchOptions: () => {
      if (!auth) {
        return true;
      }
      return {
        headers,
      };
    },
  });

  const setAuthData = (data) => {
    setAuth({ data: data });
  };

  useEffect(() => {
    setAuth({
      loading: false,
      data: JSON.parse(localStorage?.getItem("authData")),
    });
  }, []);

  const setProfileData = (data) => {
    setProfile({ pLoading: false, data: data });
  };

  useEffect(() => {
    setProfile({
      data: JSON.parse(localStorage.getItem("currentProfileData")),
    });
  }, []);
  //2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false.

  //This function will be executed every time component is mounted (every time the user refresh the page);
  useEffect(() => {
    return onAuthStateChanged(authc, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken(true);

          document.cookie =
            "userToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

          document.cookie = `userToken=${token}; path=/`;

          // console.log(token);
          const idTokenResult = await user.getIdTokenResult();
          // console.log("id", idTokenResult);
          const hasuraClaim =
            idTokenResult.claims["https://hasura.io/jwt/claims"];
          // console.log("hasura", hasuraClaim);
          if (hasuraClaim["x-hasura-default-role"] === "user") {
            setAuthData({
              jwt: token,
              id: user.uid,
              verified: user.emailVerified,
              email: user.email,
              name: user?.displayName,
            });
          } else {
            setAuthData(null);
          }
        } catch (error) {}
      } else {
        setAuthData(null);
      }
    });
  }, []);

  useEffect(() => {
    document.cookie = `userEmail=${auth?.data?.email}; path=/`;
    localStorage.setItem("authData", JSON.stringify(auth.data));
  }, [auth.data]);

  useEffect(() => {
    document.cookie = "profile=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

    document.cookie = `profile=${profile?.data?.id}; path=/`;

    localStorage.setItem("currentProfileData", JSON.stringify(profile.data));
  }, [profile.data]);
  //  1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.

  return (
    <AuthContext.Provider
      value={{ auth, profile, setAuthData, setProfileData }}
    >
      <Provider value={client}>{children}</Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
